/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 *
 *
 */

.site-layout-content {
    background: #fff;
    padding: 24px;
    min-height: 60vh;
}

code {
    margin: 0 1px;
    padding: .2em .4em;
    font-size: .9em;
    background: #f2f4f5;
    border: 1px solid #f0f0f0;
    border-radius: 3px;
}
.ant-form-item-label > label {height: 24px}
.dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
}
.dynamic-delete-button:hover {color: #777}
.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}
[data-theme="dark"] .dynamic-delete-button {color: rgba(255,255,255,.45)}
[data-theme="dark"] .dynamic-delete-button:hover {color: rgba(255,255,255,.65)}
.primary-image .ant-upload-list-picture-card-container{
    width: 100%;
    height: 100%;
}

.primary-image .ant-upload-list-picture-card .ant-upload-list-item{
    width: 100%;
    height: 100%;
}
.avatar-uploader > .ant-upload {
    width: 100%;
    height: 128px;
}

.ant-layout-content{padding: 0 2rem}
@media only screen and (max-width: 900px) {
    .site-layout-content{padding: 5px}
    .ant-layout-content{padding: 0 .7rem}
}
.login-form-card{
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}
.ant-input:focus{
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
    border-color: #0B66D6;
}
.ant-btn-link:focus{
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
    border-color: #0B66D6;
}
.ant-checkbox-input:focus{
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
    border-color: #0B66D6;
}
.bread-crumb{
    margin: 16px 0;
}